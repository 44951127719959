<template>
  <b-modal
    id="delete-modal-lg"
    v-model="modalState"
    centered
    :title="$t('Удаление')"
    @hide="$emit('update:isShowModal', false)"
  >
    <div slot="modal-footer">
      <b-button
        class="mx-1"
        variant="secondary"
        @click="$bvModal.hide('delete-modal-lg')"
      >
        {{ $t('Отмена') }}
      </b-button>
      <b-button
        variant="danger"
        @click="$emit('button-delete-clicked')"
      >
        {{ $t('Удалить') }}
      </b-button>
    </div>
    <BCardText>
      {{ $t('Вы действительно хотите удалить?') }}
    </BCardText>
    <template class="mt-1">
      <slot />
    </template>
  </b-modal>
</template>

<script>
import { BModal, BButton, BCardText } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BButton,
    BCardText,
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    deleteItem: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      modalState: false,
    }
  },
  watch: {
    isShowModal(val) {
      if (val) this.modalState = true
      else this.modalState = false
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-good-table.scss';
</style>
