<template>
  <b-row>
    <b-col
      v-for="(item, index) in data"
      :key="index"
    >
      <b-card no-body>
        <b-card-body class="d-flex justify-content-between align-items-center">
          <div class="text-truncate">
            <h2 class="mb-50 font-weight-bolder">
              {{ item.number }}
            </h2>
            <span :class="item.textColor">
              {{ item.text }}
            </span>
          </div>
          <b-avatar
            :variant="item.iconColor"
            size="45"
          >
            <feather-icon
              size="21"
              :icon="item.icon"
            />
          </b-avatar>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardBody, BAvatar,
} from 'bootstrap-vue'
// import { mapGetters } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BAvatar,
  },
  data() {
    return {
      data: [
        {
          number: 100,
          text: 'Всего ползователей',
          icon: 'UsersIcon',
          iconColor: 'light-info',
          textColor: 'text-danger',
        },
        {
          number: 10,
          text: 'Сотрудников',
          icon: 'UserIcon',
          iconColor: 'light-danger',
          textColor: 'text-success',
        },
        {
          number: 15,
          text: 'Учителей',
          icon: 'UsersIcon',
          iconColor: 'light-success',
          textColor: 'text-warning',
        },
        {
          number: 75,
          text: 'Учеников',
          icon: 'UsersIcon',
          iconColor: 'light-warning',
          textColor: 'text-primary',
        },
        {
          number: '25/100 (GB)',
          text: 'Память',
          icon: 'DatabaseIcon',
          iconColor: 'light-primary',
          textColor: 'text-warning',
        },
        {
          number: '10.11.2021',
          text: 'Период доступа',
          icon: 'CalendarIcon',
          iconColor: 'light-info',
          textColor: 'text-danger',
        },
      ],
    }
  },
  // computed: {
  //   ...mapGetters('kanban', ['getCrmStepsList']),
  // },
  methods: {
    currencyFormatter(amount) {
      const dollarUSLocale = Intl.NumberFormat('ru-RU')
      return dollarUSLocale.format(amount)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
