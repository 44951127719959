<template>
  <div>
    <head-cards />
    <filters
      :add="true"
      :search="true"
      @filtered-items="filter"
      @add-button-clicked="$router.push('/users/user/new-user')"
    />
    <b-card>
      <b-table
        hover
        show-empty
        :fields="fields"
        :items="items"
        style-class="slots_text_align vgt-table bordered"
      >

        <template
          #empty
        >
          <div class="text-center text-secondary">
            Нет данных для просмотра
          </div>
        </template>
        <template
          #cell(actions)="data"
        >
          <div class="d-flex align-items-center no-wrap">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon mr-50 p-25"
              size="sm"
            >
              <feather-icon
                size="22"
                icon="ChevronsRightIcon"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon mr-50 p-25"
              size="sm"
              @click="$router.push(`/users/user/${data.item.id}`)"
            >
              <feather-icon
                icon="EditIcon"
                size="22"
              />
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="danger"
              class="btn-icon p-25"
              size="sm"
              @click="modalDeleteUser(data.item.id)"
            >
              <feather-icon
                size="22"
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </template>

        <template
          #cell(full_name)="data"
        >
          {{ data.item.surename + ' ' + data.item.name + ' ' + data.item.middlename }}
        </template>

        <template
          #cell(role)="data"
        >
          {{ data.value.name }}
        </template>

        <template
          #cell(login)="data"
        >
          {{ data.value }}
        </template>

        <template #cell(is_active)="data">
          <b-form-checkbox
            :checked="data.value"
            name="check-button"
            switch
            inline
            disabled
          />
        </template>
      </b-table>

      <!-- pagination -->
      <!-- slot-scope="props" -->
      <template>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">{{ $t('general.show_from') }}</span>
            <b-form-select
              v-model="params.page_size"
              :options="['1', '5', '10', '15']"
              class="mx-1"
              @change="changePerPage"
            />
            <span class="text-nowrap"> {{ $t('general.show_to') }} </span>
            <!-- из {{ props.total }} -->
          </div>
          <div>
            <b-pagination
              v-model="params.page"
              :total-rows="rows"
              :per-page="params.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </b-card>

    <delete-modal>
      <!-- @button-delete-clicked="" -->
      <b-row>
        <b-col
          cols="12"
        >
          <table class="w-100 px-3">
            <tr class="d-flex justify-content-between">
              <td>
                Название филиала:
              </td>
              <td><span class="font-weight-bold">text</span></td>
            </tr>
            <tr class="d-flex justify-content-between">
              <td>
                Дата создания:
              </td>
              <td>text</td>
            </tr>
            <tr class="d-flex justify-content-between">
              <td>
                Дата окончения:
              </td>
              <td>text</td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </delete-modal>
  </div>
</template>

<script>
import {
  BCard,
  BPagination,
  BFormSelect,
  BButton,
  BFormCheckbox,
  BTable,
  BRow,
  BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import DeleteModal from '../../../../components/DeleteModal.vue'
// import DeleteModal from '@/components/DeleteModal.vue'
import toast from '@/mixins/toast'
import areYouSure from '@/mixins/areYouSure'
import filters from '@/components/filters.vue'
import HeadCards from '@/components/HeadCards.vue'

export default {
  name: 'User',
  components: {
    BTable,
    BCard,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    BButton,
    DeleteModal,
    HeadCards,
    filters,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  mixins: [toast, areYouSure],
  data() {
    return {
      rows: 1,
      isAddModal: false,
      modalType: false,
      isDeleteFileModal: false,
      searchTerm: '',
      editBranchData: null,
      deleteItemId: null,
      params: {
        page_size: 10,
        page: 1,
        ordering: null,
        search: null,
      },
      items: [
        {
          id: 1,
          name: 'Korrie1',
          surename: "O'Crevy",
          middlename: 'Aliyev',
          role: [
            {
              id: 2,
              name: 'Teacher',
            },
          ],
          login: '*Login',
          phone_number: [
            {
              tel: '+998941113212',
            },
            {
              tel: '+998941234566',
            },
          ],
          email: 'kocrevy0@thetimes.co.uk',
          is_active: true,
          profile: "G'ulom o'gli",
          subject_acces: [
            1,
          ],
          level: 2,
          start_date: '2022-01-01',
          end_date: '2022-01-17',
          class: [
            2,
          ],
          gender: 'famale',
        },
      ],
      fields: [
        {
          label: 'ID',
          key: 'id',
        },
        {
          label: 'Ф.И.О',
          key: 'full_name',
        },
        {
          label: 'Роль',
          key: 'role',
        },
        {
          label: 'Логин',
          key: 'login',
        },
        {
          label: 'Статус',
          key: 'is_active',
        },
        {
          label: 'Действия',
          key: 'actions',
        },
      ],
    }
  },
  methods: {
    modalDeleteUser(id) {
      console.log(id)
      this.$bvModal.show('delete-modal-lg')
    },
    changePerPage(page_size) {
      this.params.page_size = page_size
      this.params.page = 1
      this.FETCH_USERS_LIST(this.params)
    },
    changePage(value) {
      this.params.page = value
      this.FETCH_USERS_LIST(this.params)
    },
    filter(val) {
      this.params.search = val.search
      this.FETCH_USERS_LIST(val)
    },
  },
}
</script>
